import {MockClosureApiService} from "./mock_api_repository";
import {MainAccountClosureApiService} from "./api_repository"
import {AccountClosureApiService} from "./service"
export class AccountClosureApiServiceFactory {
    static getService(): AccountClosureApiService {
        if (isAccountClosureAPIMockEnabled()) {
            return new MockClosureApiService();
        } else {
            return new MainAccountClosureApiService();
        }
    }
}


const isAccountClosureAPIMockEnabled = () => {
    const isEnabled = process.env.REACT_APP_IS_ACCOUNT_CLOSURE_API_MOCK_ENABLED || 'false';
    return isEnabled.toLowerCase() === 'true'
}