import {BASE_URL, HandleErrorResponse} from "./common";

export const fetchUserInfo = async (userId: string) => {
    const endpoint = `${BASE_URL}/users/${userId}`;

    try {
        const response = await fetch(endpoint, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            }
        });

        if (!response.ok) {
            await HandleErrorResponse(response);
        }

        return response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}