import React, {useEffect, useState} from "react";
import {
    BrandBand,
    Button,
    GlobalNavigationBar,
    GlobalNavigationBarLink,
    GlobalNavigationBarRegion,
    Toast,
    ToastContainer
} from "@salesforce/design-system-react";
import {useNavigate, useParams} from 'react-router-dom';
import AccessRequestObjectPage from "../access_requests/AccessRequestObjectPage";
import AccountManagementObjectPage from "../account_management/AccountManagementObjectPage";
import {ToastState} from "../../utils/types/toast";
import {selectedAccountInfo} from "../../utils/types/account";
import {useUser} from "../../context/users";
import {fetchUserInfo} from "../../services/users";
import {getLoggedInUserEmail} from "../../utils/lookup/session";
import Credentials from "../../modals/Credentials";
import RefreshConfirmationModal from "../../modals/RefreshConfirmationModal";
import {CONSTANT_ACCESS_REQUEST} from "../../constants/accessRequest";
import AccessRequestConfirmationModal from "../../modals/AccessRequestConfirmationModal";

type AppHeaderProps = {
    requestType?: string;
};


const AppHeader: React.FC<AppHeaderProps> = ({ requestType }) => {
    const [activeSection, setActiveSection] = useState<string>('AccessRequestSection');
    const { accessId } = useParams<{ accessId: string }>();
    const [activeSubTab, setActiveSubTab] = useState<number>(0);
    const [selectedAccount, setSelectedAccount] = useState<selectedAccountInfo>({
        account_id: "",
        role: ""}
    )
    const [fetchingUser, setFetchingUser] = useState<boolean>(false)
    const [requestedPath, setRequestedPath] = useState<string>(requestType || "")
    const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);

    const [localStorageUser] = useState<string>(
        getLoggedInUserEmail()
    );
    const { setUser } = useUser();

    const [showGIA2HBanner] = useState<boolean>(
        isGIA2HJIT()
    )

    function isGIA2HJIT() {
        let url = window.location.origin;
        return url.indexOf('prod.gia2h') !== -1;
    }

    const [toast, setToast] = useState<ToastState>({
        isVisible: false,
        message: {
            details: "",
            heading: "",
            headingLink: ""
        },
        variant: "error"
    });

    const getTabContent = () => {
        switch (activeSection) {
            case 'AccessRequestSection':
                return <AccessRequestObjectPage accessId={accessId} requestType={requestType} activeTab={activeSection}
                                                setActiveTab={setActiveSection} activeSubTab={activeSubTab}
                                                setActiveSubTab={setActiveSubTab} selectedAccount={selectedAccount}
                                                setSelectedAccount={setSelectedAccount} toast={toast} setToast={setToast}/>;
            case 'AccountManagementSection':
                return <AccountManagementObjectPage activeTab={activeSection} requestedURL={new URL(requestedPath)}
                                                    setActiveTab={setActiveSection} activeSubTab={activeSubTab}
                                                    setActiveSubTab={setActiveSubTab} selectedAccount={selectedAccount}
                                                    setSelectedAccount={setSelectedAccount} toast={toast} setToast={setToast} fetchingUser={fetchingUser}/>;
            default:
                return <AccessRequestObjectPage accessId={accessId} requestType={requestType} activeTab={activeSection}
                                                setActiveTab={setActiveSection} activeSubTab={activeSubTab}
                                                setActiveSubTab={setActiveSubTab} selectedAccount={selectedAccount}
                                                setSelectedAccount={setSelectedAccount} toast={toast} setToast={setToast}/>;
        }
    };

    const confirmRefreshModal = () => {
        setShowRefreshModal(false);
        localStorage.removeItem('loginRetryCount');
        navigate("/login");
    }

    useEffect(() => {
        // Fetch user information and update the context on component mount
        const fetchUser = async () => {
            try {
                setFetchingUser(true)
                const userInfo = await fetchUserInfo(localStorageUser);
                setUser(userInfo);
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setFetchingUser(false)
            }
        };
        fetchUser();
    }, [localStorageUser, setUser]);

    // store the requested url only to render the current view. once loaded discard it
    // page navigation memoization is not required
    useEffect(() => {
        setRequestedPath(window.location.href)
    }, [window.location.href]);

    useEffect(() => {
        if (requestType === 'slack_channel_update' || requestType === 'service_team_update' || requestType === 'account_closure' || requestType === 'stackset_drift') {
            setActiveSection('AccountManagementSection');
        }
        let url = window.location.origin;
        if (url.indexOf('dashboard.new-ui.') !== -1) {
            url = url.replace('dashboard.new-ui.', 'dashboard.');
            window.location.href = url
        }
    }, [requestType]);

    const navigate = useNavigate();
    useEffect(() => {
        if( localStorage.getItem("authenticated") === "false") {
            let loginRetry = parseInt(localStorage.getItem('loginRetryCount') || "0", 10);
            if (loginRetry >= 3) {
                setShowRefreshModal(true);
            } else {
                setTimeout(() => {
                    navigate("/login");
                }, 1000)
            }
        }
    }, [localStorage.getItem("authenticated")]);

    return (
        <div>
            <BrandBand
                className="custom-brand-band-class"
                id="brand-band-large"
                size="large"
            >
                {toast.isVisible && (
                    <ToastContainer>
                        <Toast
                            labels={toast.message}
                            variant={toast.variant}
                            onRequestClose={() => setToast({
                                isVisible: false,
                            })}
                            duration={20000}
                        />
                    </ToastContainer>
                )}
                {showGIA2HBanner &&
                    <div className="slds-text-align_center">
                        <div className="slds-text-color--error">
                            <div className="slds-box slds-box_small slds-box_x-small slds-theme_alt-inverse">
                                <div className="slds-text-color--inverse">
                                    <b> You are accessing a U.S. Government information system. Information system
                                    usage may be monitored, recorded, and subject to audit
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <GlobalNavigationBar>
                    <GlobalNavigationBarRegion region="primary">
                        <div className='slds-m-top--xxx-small'>
                            <img
                                src={process.env.PUBLIC_URL + '/salesforce-logo.jpg'}
                                alt="Salesforce Logo"
                                style={{ width: '60px', height: '32px' }}
                            />
                        </div>
                        <div className='slds-text-title_bold slds-m-top--x-small slds-m-right--medium'>
                            PCSK JIT
                        </div>
                    </GlobalNavigationBarRegion>
                    <GlobalNavigationBarRegion region="secondary">
                        <GlobalNavigationBarLink label="Access Requests"
                                                 id="access_requests"
                                                 onClick={() => {
                                                     setActiveSection('AccessRequestSection' );
                                                     setActiveSubTab(0);
                                                     setSelectedAccount({
                                                         account_id: ""
                                                     });
                                                 }}
                                                 active={activeSection === 'AccessRequestSection' || activeSection === ''}>
                        </GlobalNavigationBarLink>
                        <GlobalNavigationBarLink label="Account Management"
                                                 id="account_management"
                                                 onClick={() => {
                                                     setActiveSection('AccountManagementSection');
                                                     setSelectedAccount({
                                                         account_id: ""
                                                     })
                                                 }}
                                                 active={activeSection === 'AccountManagementSection'}>
                        </GlobalNavigationBarLink>
                    </GlobalNavigationBarRegion>
                    <GlobalNavigationBarRegion region="tertiary">
                        <div className='slds-p-right--medium slds-p-top_xxx-small slds-grid'>
                            <div className="slds-col">
                                <b>
                                    <Button label="Feedback"
                                            onClick={(e: any) => {
                                                e.preventDefault(); // Prevent the default behavior (opening the link)
                                                window.open('https://forms.gle/avVGyxrWSB2qYuJ7A', '_blank');
                                            }}
                                            variant="base"/>
                                </b>
                            </div>
                        </div>
                    </GlobalNavigationBarRegion>
                </GlobalNavigationBar>
                {getTabContent()}
                <RefreshConfirmationModal
                    isOpen={showRefreshModal}
                    toggleModal={(isOpen) => setShowRefreshModal(isOpen)}
                    onConfirm={confirmRefreshModal}
                />
            </BrandBand>
        </div>
    );
}

export default AppHeader;
