export const CONSTANT_ACCOUNT_CLOSURE = {
    GET_STATUS_THEME: (status: string): string => {
        status = `${status}`.toLowerCase()
        if (status.includes("failed")) {
            return "slds-theme--error"
        }
        if (status.includes("limit")) {
            return  "slds-theme_warning"
        }
        if (status === "closed") {
            return  "slds-theme--success"
        }
        if (status === "expired" || status.includes("cancel")) {
            return "slds-theme_warning"
        }
        return "slds-progress-bar__value"
    },
}