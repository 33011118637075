import {MockDriftDetectionApiService} from "./mock_api_repository";
import {MainDriftDetectionApiService} from "./api_repository"
import {DriftDetectionApiService} from "./service"

export class DriftDetectionApiServiceFactory {
    static getService(): DriftDetectionApiService {
        if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_API === 'true') {
            return new MockDriftDetectionApiService();
        } else {
            return new MainDriftDetectionApiService();
        }
    }
}